<template>
  <a-range-picker
    :locale="locale"
    v-model:value="timeValue"
    @change="timeChange"
    @openChange="openChange"
    :disabledTime="disabledTime"
  />
</template>

<script>
import { DatePicker } from 'ant-design-vue';
import { handleChangeSearchDate } from "@/utils/index.js";
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import { ref, toRefs } from 'vue';
import dayjs from 'dayjs';

dayjs.locale('zh-cn');

export default {
  props: {
    defaultDate: String
  },
  components: {
    [DatePicker.name]: DatePicker
  },

  setup (props, vm) {
    const { defaultDate } = toRefs(props, "defaultDate");
    const timeValue = ref('');
    const customWeekStartEndFormat = ref('');
    const timeChange = (val, dateString) => {
      handleChangeSearchDate(dateString, 'rangeDate');
      vm.emit('dataPickerTime', val);
    };
    // 初始化日期
    const handleDateInit = () => {
      if (defaultDate.value) {
        timeValue.value = [dayjs(defaultDate.value[0]), dayjs(defaultDate.value[1])];
      }
    };
    // 弹出日历
    const openChange = (val) => {
      console.log(val);
    };

    // 不可选择的时间
    const disabledTime = (val => {
      console.log(val);
    });
    handleDateInit();
    return {
      locale,
      timeValue,
      timeChange,
      openChange,
      disabledTime,
      customWeekStartEndFormat,
      handleDateInit
    };
  }
}
</script>

<style lang="less">
.ant-picker {
  width: 100%;
  height: 100%;
  color: #eaedff !important;
  background: #11151ee5 !important;
  border-color: #ffffff4d !important;
  border-radius: 8px !important;
}

.ant-picker-input > input {
  color: #eaedff !important;
}
.ant-picker-suffix {
  color: #eaedff;
  .anticon {
    color: #eaedff;
  }
}
.ant-picker-separator {
  color: #eaedff;
}
.ant-picker-focused {
  border-color: #ffffff4d;
  box-shadow: none !important;
  border-right-width: 1px !important;
  outline: 0;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #ffffff4d;
  border-right-width: 1px !important;
}
.ant-picker-dropdown {
  color: #eaedff !important;
  .ant-picker-panel-container {
    background: #11151ee5 !important;
    box-shadow: none !important;
    .ant-picker-panel {
      border: 1px solid #ffffff4d !important;
    }
  }
  .ant-picker-header {
    color: #eaedff;
    border-bottom: 1px solid #2b2c33;
    button {
      color: #eaedff;
    }
  }
  .ant-picker-content th {
    color: #eaedff;
  }
  .ant-picker-cell {
    color: #eaedff;
  }
  .ant-picker-week-panel-row-selected td,
  .ant-picker-week-panel-row-selected:hover td {
    background: #ebc5b826 !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-radius: 50%;
    border-color: var(--mainChartColor);
  }
  .ant-picker-week-panel-row:hover td {
    background: #ebc5b826 !important;
  }
}
</style>
