<template>
  <div class="app-safe">
    <div class="app-safe-filter">
      <div class="app-safe-filter-select">
        <a-select
          ref="select"
          style="width: 160px"
          :value="level"
          :options="selectOption"
          @change="handleSelectChange"
        >
        </a-select>
      </div>
      <div class="app-safe-filter-datepicker">
        <RangePicker @dataPickerTime="selectTime" :defaultDate="defaultDate" />
      </div>
    </div>
    <div class="app-safe-top">
      <div class="app-safe-card">
        <span class="card-title">漏洞风险类型</span>
        <div class="app-safe-chartbox">
          <Card-border></Card-border>
          <PieChart :pieData="vulData" :domId="`appSafeRiskType`" />
        </div>
      </div>
      <div class="app-safe-card">
        <span class="card-title">漏洞风险分布</span>
        <div class="app-safe-chartbox">
          <Card-border></Card-border>
          <PieChart :pieData="vulDetailData" :domId="`appSafeRiskDis`" />
        </div>
      </div>
      <div class="app-safe-card">
        <span class="card-title">漏洞风险应用分布TOP 50</span>
        <div class="app-safe-chartbox">
          <Card-border></Card-border>
          <PieChart :pieData="riskApp50" :domId="`appSafeRisk50`" />
        </div>
      </div>
    </div>
    <div class="app-safe-bottom">
      <div class="app-safe-card">
        <span class="card-title">检出风险数量变化</span>
        <div class="app-safe-chartbox">
          <Card-border></Card-border>
          <LineChart :data="trendData" :chartIndex="0" />
        </div>
      </div>
      <div class="app-safe-card">
        <span class="card-title">风险应用 TOP 10</span>
        <div class="app-safe-chartbox">
          <Card-border></Card-border>
          <BarChart
            :barData="riskAppNum"
            :barDataLegend="riskLabel"
            :barXLabel="riskAppName"
            :totalDataTotal="riskAppNumTotal"
            :chartIndex="`appTop10`"
          ></BarChart>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { ref, reactive } from 'vue';
import dayjs from 'dayjs';
import { getAnalysis } from '@/api/appSafe.js';
import { Select } from 'ant-design-vue';
import LineChart from './common/lines.vue';
import BarChart from './common/bar.vue';
import CardBorder from '@/components/common/card-border.vue';
import RangePicker from '@/components/common/range-picker.vue';
import PieChart from './common/pie-chart.vue';
import { getImgPath } from '@/utils/addColor.js';

export default {
  name: 'Application',
  components: {
    [Select.name]: Select,
    CardBorder,
    RangePicker,
    PieChart,
    LineChart,
    BarChart
  },
  setup () {
    //演示数据用
    const defaultDate = localStorage.rangeDate?.split(',') || [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")];
    let monday = dayjs(defaultDate[0]).format("YYYY-MM-DD");
    let sunday = dayjs(defaultDate[1]).format("YYYY-MM-DD");
    const level = ref('total');
    const allData = ref({});
    const selectOption = ref([
      { label: '全部', value: 'total', level: 3 },
      { label: '高危', value: "high", level: 3 },
      { label: '中危', value: "middle", level: 2 },
      { label: '低危', value: "low", level: 1 },
    ]);
    const vulData = ref([]);//漏洞风险类型
    const vulDetailData = ref([]); //漏洞风险分布
    const riskApp50 = ref([]);
    const trendData = ref([]);
    const riskAppNum = ref([]);
    const riskAppName = ref([]);
    const riskLabel = ref([
      "高危", "中危", "低危"
    ]);
    const timePicker = reactive({
      st: monday,
      et: sunday,
      dt: monday
    });
    const handleSelectChange = (val) => {
      level.value = val;
      getChartData();
    };
    // 日历选择
    const selectTime = (val) => {
      monday = dayjs(val[0]).format("YYYY-MM-DD");
      sunday = dayjs(val[1]).format("YYYY-MM-DD");
      timePicker.st = monday;
      timePicker.et = sunday;
      let params = {
        starttime: monday,
        endtime: sunday
      };
      getAnalysisData(params);
    };

    const getAnalysisData = async (params) => {
      const { data } = await getAnalysis(params || {});
      const { android = {}, trend = {} } = data;
      allData.value = { ...android, trend };
      getChartData();
    };
    const getChartData = () => {
      const vulDetailLevel = selectOption.value.find((item) => {
        return item.value === level.value;
      });
      //漏洞风险类型
      vulData.value = allData.value.vul.map((item, index) => {
        let obj = {
          "name": item.name, "value": item[level.value]
        };
        if (index === 0) {
          obj.label = { show: true };
        }
        return obj;
      });
      //漏洞风险分布
      const vulDetailFilterData = allData.value.vulDetail.filter((item) => {
        if (item.level === vulDetailLevel.level) {
          return item;
        }
      });
      vulDetailData.value = vulDetailFilterData ? vulDetailFilterData.map((item, index) => {
        let obj = {
          "name": item.name, "value": item.total
        };
        if (index === 0) {
          obj.label = { show: true };
        }
        return obj;
      }) : [];
      //漏洞风险应用分布TOP 50
      riskApp50.value = allData.value["app"].map((item, index) => {
        let obj = {
          "name": item.appName, "value": item[level.value]
        };
        if (index === 0) {
          obj.label = { show: true };
        }
        return obj;
      });
      //检出风险数量变化
      trendData.value = allData.value.trend.map((item) => {
        return {
          "name": item.date, "value": item.android[level.value]
        };
      });

      const highData = [];
      const middleData = [];
      const lowData = [];
      const appNameArr = [];
      allData.value["appTop10"].forEach(item => {
        appNameArr.push(item.appName);
        highData.push(item.high);
        middleData.push(item.middle);
        lowData.push(item.low);
      });
      const newData = [highData, middleData, lowData];
      riskAppNum.value = newData;
      riskAppName.value = appNameArr;
    };

    getAnalysisData({
      starttime: monday,
      endtime: sunday
    });
    return {
      level,
      selectOption,
      handleSelectChange,
      vulData,
      vulDetailData,
      riskApp50,
      trendData,
      riskAppNum,
      riskAppName,
      riskLabel,
      selectTime,
      getImgPath,
      defaultDate
    };
  },
}
  </script>
  
  <style lang="less">
@import url('./index.less');
</style>
  