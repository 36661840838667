<template>
  <div class="riskApp">
    <div class="compliance_rate">
      <div class="compliance_header">
        合规率
        <span>
          {{
            options.appSummary
              ? `${
                  options.appSummary.safety == 0 &&
                  options.appSummary.total == 0
                    ? '0'
                    : (
                        (options.appSummary.safety / options.appSummary.total) *
                        100
                      ).toFixed(2)
                }%`
              : '0%'
          }}
        </span>
      </div>
      <div class="compliance_body">
        <div class="left">
          {{ options.appSummary ? options.appSummary.safety : '' }}
        </div>
        <div class="right">
          {{ options.appSummary ? options.appSummary.total : '' }}
        </div>
      </div>
    </div>
    <div class="main">
      <div class="main_type">
        <div class="left">
          <span></span>
          合规
        </div>
        <div class="right">
          <span></span>
          风险
        </div>
      </div>
      <div class="main_box">
        <div class="main_up"></div>
        <div class="main_header">
          风险主体总数
          <span>
            {{ options.risk ? options.risk.program + options.risk.sdk : '0' }}
          </span>
        </div>
        <div class="main_body">
          <div class="left">
            <div class="name">APP</div>
            <div class="value">
              {{ options.risk ? options.risk.program : '0' }}
            </div>
          </div>
          <div class="right">
            <div class="name">SDK</div>
            <div class="value">
              {{ options.risk ? options.risk.sdk : '0' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, watch } from 'vue';
export default {
  props: {
    wh: {
      type: String,
      default: '100%'
    },
    data: {
      type: Object,
      default: {}
    }
  },
  setup (props) {
    //   const logoConfig = ref({});
    const { data } = toRefs(props);
    const total = ref('100%');
    const safety = ref('0%');
    const options = ref({});
    const program = ref('50%');
    const sdk = ref('50%');
    watch(data, () => {
      options.value = {
        appSummary: {
          safety: data.value.appSummary.safety || 0,
          total: data.value.appSummary.total || 0,
        },
        risk: {
          program: data.value.risk.program || 0,
          sdk: data.value.risk.sdk || 0,
        }
      };
      if (!(data.value.appSummary.safety == 0 && data.value.appSummary.total == 0)) {
        let val = data.value.appSummary.safety / data.value.appSummary.total * 100;
        total.value = `${100 - val}%`;
        safety.value = `${val}%`;
      } else {
        total.value = '100%';
        safety.value = `0%`;
      }
      if (!(data.value.risk.program == 0 && data.value.risk.sdk == 0)) {
        let valrisk = data.value.risk.sdk / (data.value.risk.program + data.value.risk.sdk) * 100;
        program.value = `${100 - valrisk}%`;
        sdk.value = `${valrisk}%`;
      } else {
        program.value = `50%`;
        sdk.value = `50%`;
      }
    });
    return { options, total, safety, program, sdk };
  },
}
</script>

<style scoped lang="less">
@font-face {
  font-family: 'Azonix';
  // src: url('#{$fa-font-path}/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('../../../assets/font/Azonix.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}

.riskApp {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  z-index: 1;

  .compliance_rate {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .compliance_header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 22px;
      font-family: PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;

      span {
        font-family: Azonix;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: right;
      }
    }

    .compliance_body {
      display: flex;
      gap: 2px;
      //styleName: 数据/26px;
      font-family: Azonix;
      font-size: 26px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0.1em;
      text-align: left;
      color: rgba(15, 21, 32, 1);
      border-radius: 8px;
      overflow: hidden;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: v-bind(safety);
        height: 60px;
        background-color: rgba(88, 193, 255, 1);
        border-radius: 8px 0 0 8px;
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        width: v-bind(total);
        height: 60px;
        background-color: rgba(255, 153, 102, 1);
        border-radius: 0 8px 8px 0;
      }
    }
  }

  .main {
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: calc(100% - 106px);

    .main_type {
      display: flex;
      justify-content: center;
      gap: 16px;

      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        span {
          display: inline-block;
          width: 12px;
          height: 12px;
          background-color: rgba(88, 193, 255, 1);
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        span {
          display: inline-block;
          width: 12px;
          height: 12px;
          background-color: rgba(255, 153, 102, 1);
        }
      }
    }

    .main_box {
      display: flex;
      flex-direction: column;
      gap: 12px;
      width: 100%;
      height: calc(100% - 22px);
      background-color: rgba(44, 45, 46, 0.6);
      padding: 20px;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;

      .main_up {
        background: url(../../../assets/images/up.png) no-repeat;
        width: 20px;
        height: 8px;
        position: absolute;
        top: -8px;
        left: 56%;
      }

      .main_header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 22px;
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;

        span {
          font-family: Azonix;
          font-size: 20px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: right;
        }
      }

      .main_body {
        display: flex;
        gap: 2px;
        width: 100%;
        height: calc(100% - 22px);
        color: #fff;

        .left {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 8px;
          width: v-bind(program);
          height: 100%;
          background-color: rgba(204, 102, 102, 0.4);
          border-radius: 8px 0 0 8px;
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 8px;
          width: v-bind(sdk);
          height: 100%;
          background-color: rgba(204, 136, 102, 0.4);
          border-radius: 0 8px 8px 0;
        }

        .name {
          font-family: PingFang SC;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0em;
          text-align: left;
        }

        .value {
          font-family: Azonix;
          font-size: 26px;
          font-weight: 400;
          line-height: 26px;
          letter-spacing: 0.1em;
          text-align: left;
        }
      }
    }
  }
}
</style>