import dayjs from "dayjs";

export const dispatchEventStroage = () => {
    const signSetItem = localStorage.setItem;
    localStorage.setItem = function (key, val) {
        let setEvent = new Event('setItemEvent');
        setEvent.key = key;
        setEvent.newValue = val;
        window.dispatchEvent(setEvent);
        signSetItem.apply(this, arguments);
    };
};

// 获取url中字段
export const getURLParameter = (name) => {
    const queryString = window.location.hash;
    console.log(queryString);
    if (queryString.indexOf('?') !== -1 && queryString.indexOf('&' !== -1)) {
        let params = queryString.split('?')[1];
        params = params.split('&');
        let newParams = {};
        params.forEach((item) => {
            const key = item.split('=')[0];
            const value = item.split('=')[1];
            newParams[key] = value;
        });
        return newParams[name];
    } else {
        return '';
    }
};
//存储数据日期
export const handleChangeSearchDate = (date, key = 'searchDate',) => {
    localStorage.setItem(key, date);
};
//获取存储日期
export const getSearchDate = () => {
    const nowDate = dayjs().format("YYYY-MM-DD");
    return localStorage.searchDate || nowDate;
};