<template>
  <div class="asset-wrap">
    <Card-border></Card-border>
    <iframe :src="url" frameborder="0"> </iframe>
  </div>
</template>
  
  <script>
import { ref, watch } from 'vue';
import CardBorder from '@/components/common/card-border.vue';
import { useRouter } from 'vue-router';
export default {
  components: {
    CardBorder,
  },
  setup () {
    const url = ref();
    const router = useRouter();
    // 监听路由变化
    watch(
      () => router.currentRoute.value.path,
      () => {
        // let origin = window.location.origin === 'https://local.wentian.360.cn' ? 'https://local.wentian.360.cn' : `${window.location.origin}/jiagu`;
        let origin = `${window.location.origin}/jiagu`;
        url.value = `${origin}/${window.location.hash}`;
      },
      { immediate: true }
    );
    return {
      url
    };
  },

}
</script>
  
  <style lang="less">
@import url('./index.less');
</style>
  